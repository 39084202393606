.list_root {
    background: var(--background);
    padding: 72px 0px;
    height: 100%;
    min-height: 100vh;
}

.list_root>h2:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list_root>div:first-of-type {
    display: flex;
    justify-content: center;
    padding: 34px 0px;
}

.list_container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
}

.list_item {
    width: 450px;
    height: auto;
    background: var(--background-paper);
    box-shadow: 1px 0px 15px 2px var(--shadow-paper);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.list_item>div:last-child {
    display: flex;
    flex-direction: column;
    margin: 16px;
    gap: 8px;
}

.list_item>div:last-child :last-child {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.list_item>div:last-child :last-child span {
    font-size: 24px;
    font-family: 'Avenir Light';
    color: var(--text_dark);
}

.list_item>img {
    width: 100%;
    height: 200px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    object-fit: cover;
    object-position: 50% 50%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}

.list_item_instructure {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: -50px;
}

.list_item_instructure>img {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    clip-path: circle(100%);
    object-fit: cover;
    object-position: top;
}

@media (max-width: 900px) {
    .list_container {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    .list_item{
        width: auto;
    }

    .list_root{
        padding: 40px 0px;
    }

    .list_root>div:first-of-type{
        padding: unset;
    }
}