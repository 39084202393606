.about_section_gallery {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 24px;
}

.about_section_gallery_images {
    filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    height: 200px;
    width: 200px;
    border-radius: 100px;
    object-fit: cover;
    object-position: 50% 0%;
    cursor:pointer;
}

.about_section_gallery_images:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0%);
}

@media (max-width: 900px) {
    .about_section_gallery_images {
        height: 150px;
        width: 150px;
    }
}

