@font-face {
  font-family: "Avenir Light";
  src: url(./fonts/Avenir_Light.ttf);
}

@font-face {
  font-family: "Sacramento";
  src: url(./fonts/Sacramento-Regular.ttf);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: "Avenir Light", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (prefers-color-scheme: light) {
  :root {
    --logo: #9bbcac;
    --titles: #1f4e79;
    --buttons: #fff0c1;
    --buttons_hover: #e3cd8b;
    --buttons_text: #2f2e2e;
    --button_text_hover: #e3cd8b;
    --background: #fafafa;
    --background-paper: #fff;
    --background-partners: #1f4e79;
    --background-forms: #fefefe;
    --text_dark: #2f2e2e;
    --text_base: #a0a09f;
    --text-light: #dce6f7;
    --divider: #e3cd8b;
    --footer: #2f2e2e;
    --internal-footer: #dce6f7;
    --footer_text: #fff;
    --shadow-paper: rgba(224, 224, 224, 1);
    --shadow_home: rgba(250, 250, 250, 1);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --logo: #9bbcac;
    --titles: #89bff2;
    --buttons: #fff0c1;
    --buttons_hover: #e3cd8b;
    --buttons_text: #2f2e2e;
    --button_text_hover: #e3cd8b;
    --background: #2c2c2c;
    --background-paper: #444444;
    --background-partners: #104462;
    --background-forms: #b0b0b0;
    --text_dark: #fff;
    --text_base: #f4f4f4;
    --text-light: #dce6f7;
    --divider: #e3cd8b;
    --footer: #111111;
    --internal-footer: #dce6f7;
    --footer_text: #fefefe;
    --shadow-paper: rgb(53, 53, 53);
    --shadow_home: rgb(44, 44, 44);
  }
}



h1 {
  font-family: 'Avenir Light';
  font-size: 40px;
  color: var(--text_base);
  font-weight: unset;
  margin: 0;
}

h2 {
  font-family: "Sacramento";
  font-size: 34px;
  color: var(--titles);
  font-weight: unset;
}

h3 {
  font-family: 'Avenir Light';
  font-size: 34px;
  color: var(--titles);
  font-weight: unset;
}

h4 {
  font-family: 'Avenir Light';
  font-size: 30px;
  color: var(--titles);
  font-weight: unset;
}

h5 {
  font-size: 16px;
  font-family: "Avenir Light";
  color: var(--titles);
  font-weight: unset;
  margin: 0;
}

.body1 {
  font-size: 16px;
  font-family: "Avenir Light";
  color: var(--text_base);
}

.body2 {
  font-size: 14px;
  font-family: "Avenir Light";
  color: var(--text_base)
}

button {
  border: none;
  background: var(--buttons);
  height: 40px;
  width: auto;
  border-radius: 20px;
  font-family: "Avenir Light";
  font-size: 14px;
  letter-spacing: 0.1em;
  color: var(--buttons_text);
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}

button:hover {
  background: var(--buttons_hover);
  color: var(--background-paper);
}

.button_text {
  background: none;
  border: none;
  width: fit-content;
  font-size: 14px;
  letter-spacing: 0.2em;
  color: var(--text_base);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 0px;
  cursor: pointer;
}

.button_text:hover {
  color: var(--button_text_hover);
  border: none;
  background: none;
}

.button_text span {
  font-size: 14px;
}

.divider_vertical {
  height: auto;
  border: 0.5px dashed var(--divider);
}

.divider_horizontal {
  border-bottom: 1px dashed var(--divider);
  margin: 16px 0px;
  width: auto;
}