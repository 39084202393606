.personal_description_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 72px 72px;
    min-height: 100vh;
}

.personal_description_root>:last-child {
    display: flex;
    flex-direction: row;
    gap: 72px;
    margin-top: 72px;
}

.personal_description_overview {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-basis: 50%;
}

.personal_description_overview>:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personal_description_overview h1 {
    font-family: "Sacramento";
    color: var(--titles);
    font-size: 50px;
}

.personal_description_overview h2 {
    font-family: "Avenir light";
    color: var(--text_base);
    font-size: 20px;
    margin: 0;
    text-align: center;
}

.personal_description_overview_avatar {
    position: relative;
    display: flex;
    justify-content: center;
}

.personal_description_overview_avatar>:first-child {
    border-radius: 50%;
    overflow: hidden;
    height: 300px;
}

.personal_description_overview_avatar>:last-child {
    position: absolute;
    top: -1px;
    margin-left: 28px;
    height: 300px;
    width: 300px;
    border: 2px solid var(--text_dark);
    border-radius: 50%
}

.personal_description_overview>:last-child {
    font-family: "Avenir light";
    font-size: 16px;
    color: var(--text_base);
    text-align: justify;
}

.personal_description_details {
    flex-basis: 50%;
}

.personal_description_details h3 {
    font-size: 20px;
    color: var(--text_dark);
    margin: 72px 0px 40px;
}

.personal_description_details_person_item {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.personal_description_details_person_item>:first-child {
    flex-basis: 0%;
    text-align: end;
    display: "flex";
    flex-direction: "column";
}

.personal_description_details_person_item_date {
    letter-spacing: 0.2em;
    font-size: 14px;
}

.personal_description_details_person_item p {
    font-family: "Avenir light";
    color: var(--text_base);
    font-size: 16px;
}

.personal_description_details_person_item>:last-child {
    text-align: justify;
    display: "flex";
    flex-direction: "column";
}

.divider {
    height: auto !important;
}

@media (max-width: 900px) {
    .personal_description_root {
        padding: 24px 0px;
    }

    .personal_description_root>:last-child {
        flex-direction: column;
    }

    .personal_description_overview {
        padding: 0px 24px;
    }

    .personal_description_details {
        padding: 0px 24px;
    }

    .personal_description_details_person_item {
        flex-direction: row;
        margin-bottom: 40px;
    }

    .personal_description_details h3 {
        margin: 40px 0px 40px;
    }

    .personal_description_root .button_text {
        padding-left: 24px;
    }
}