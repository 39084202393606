.app_home_root {
    display: flex;
    flex-direction: column;
    height: 95vh;
}

.app_home_section_one {
    height: 40%;
    background-image: linear-gradient(to bottom, rgba(255, 240, 193, 0.17), var(--shadow_home)), url("./../../../public/assets/home.jpeg");
    background-repeat: no-repeat;
    background-position: 0% 6%;
    background-size: cover;
    opacity: 0.7;
}

.app_home_section_two {
    height: 70%;
    display: flex;
    flex-direction: column;
}

.app_home_section_two_logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 40px;
    font-family: 'Sacramento';
    color: var(--logo);
    letter-spacing: 0.4em;
    align-items: flex-end;
    margin-left: -8%;
}

.app_home_section_two_logo span {
    margin-left: -8%;
}

.app_home_section_two_logo img {
    height: 300px;
    width: 350px;
}

.app_home_section_two_slogan {
    font-size: 18px;
    font-family: 'Avenir Light';
    letter-spacing: 0.4em;
    color: var(--text_base);
    margin-top: 28px;
    text-align: center;
}

.app_home_section_two_button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.app_home_button {
    border: none;
    background: var(--buttons);
    height: 40px;
    width: auto;
    border-radius: 20px;
    font-family: "Avenir Light";
    font-size: 14px;
    letter-spacing: 0.1em;
    color: var(--buttons_text);
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.app_home_button:hover {
    background: var(--buttons_hover);
    color: var(--background-paper);
}


@media (max-width: 900px) {
    .app_home_section_one {
        height: 35%;
        opacity: 1;
    }

    .app_home_section_two {
        height: 80%;
    }

    .app_home_section_two_logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 40px;
        font-family: 'Sacramento';
        color: var(--logo);
        letter-spacing: 0.4em;
        align-items: center;
        padding: 0% 0% 0% 10%;
    }

    .app_home_section_two_logo span {
        margin: 0;
        padding-top: 24px;
    }

    .app_home_section_two_logo img {
        height: 200px;
        width: 250px;
    }

    .app_home_section_two_slogan {
        margin-top: 16px;
    }
}