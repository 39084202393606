.posts_root {
    display: flex;
    flex-direction: column;
    margin: 72px 10%;
}

.posts_root h2 {
    text-align: center;
}

.posts_content {
    display: grid;
    grid-template-columns: auto auto;
    gap: 72px;
    margin-top: 40px;
}

.post_item {
    display: flex;
    justify-content: center
}

@media (max-width: 900px) {

    .posts_root {
        margin: 24px;
    }

    .posts_content {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 40px;
    }
}