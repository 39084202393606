/** Web styles**/
.app_navbar-links {
    display: flex;
    justify-content: center;
    list-style: none;
}

.app_navbar-links li {
    cursor: pointer;
}

.app_navbar-links li a {
    display: flex;
    justify-content: center;
    width: 10vw;
    text-decoration: none;
    padding: 14px 24px;
    font-size: 12px;
    color: var(--text_base);
    font-family: 'Avenir Light';
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.app_navbar-links li a:active {
    border-bottom: 2px solid var(--buttons_hover);
}

.active {
    border-bottom: 2px solid var(--buttons_hover);
    color: var(--buttons_hover) !important;
}

.app_navbar-links li a:hover {
    border-bottom: 2px solid var(--buttons_hover);
    color: var(--buttons_hover);
}


/** Mobile styles **/

.nav-container {
    display: block;
    position: relative;
    height: 40px;
}

.menu-items {
    display: flex;
    position: relative;
    z-index: 1;
    background-color: var(--background);
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    transform: translate(-150%);
    transition: transform 0.5s ease-in-out;
    padding-top: 120px;
}

.menu-items li {
    list-style: none;
    padding: 24px 0px;
}

.menu-items a {
    text-decoration: none;
    color: var(--text_base);
    font-family: 'Avenir Light';
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 12px;
}


/** Humburger styles **/

.nav-container .checkbox {
    position: absolute;
    display: block;
    height: 24px;
    width: 24px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.nav-container .hamburger-lines {
    display: block;
    height: 20px;
    width: 24px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--logo);
}

.nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.nav-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked~.logo {
    display: none;
}