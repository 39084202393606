.app_about_root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 72px 0px 0px;
    margin: "0 10%";
    height: 100%;
    min-height: 100vh;
}

.app_about_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 10%;
    gap: 72px;
    height: 50vh;
    text-align: justify;
}

.app_testemonials_root {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 160px 0px 80px 0px;
    gap: 40px;
}

.app_testemonials_root>:first-child {
    color: var(--divider);
}

.app_testemonials_item {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: auto;
    justify-content: space-between;
    align-items: center;
    padding-top: 5%;
}

.app_testemonials_item span {
    width: 60%;
    text-align: center;
}

.app_testemonials_item>:first-child {
    font-style: italic;
}

.app_testemonials_item>:last-child {
    font-weight: bold;
    color: var(--text_dark);
}


:root {
    --swiper-theme-color: var(--divider) !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}

@media (max-width: 900px) {
    .app_about_main {
        flex-direction: column;
        height: auto;
        padding: unset;
        width: 100%;
    }

    .app_about_root {
        padding: 24px 0px 0px;
    }

    .divider_vertical {
        display: none;
    }

    .app_about_main>:first-child {
        padding: 0px 24px
    }

    .app_testemonials_root {
        width: 100%;
        margin: 100px 0px 80px 0px;
    }
}