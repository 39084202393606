.app_about_footer {
    background-color: var(--background-partners);
    width: 100%;
    color: var(--text-light);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    padding: 72px 0px;
    margin-top: 72px;
}

.app_about_footer>div {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 24px;
}

.app_about_footer>div h2 {
    color: var(--text-light) !important;
    margin: 0;
}

.app_about_partners {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.app_about_partners_item {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: var(--background-forms);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: 100% 100%;
    filter: grayscale(1);
    background-repeat: no-repeat;
}

.app_about_partners_item :hover {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    cursor: pointer;
    color: transparent;
}

.app_about_partners_item>span {
    color: var(--text_dark);
    font-family: "Avenir light";
    padding: 20px;
    cursor: pointer
}

.app_about_contactus {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
}

.app_about_contactus_item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: var(--text-light);
    font-family: "Avenir light";
}

.app_about_contactus_item span:first-child {
    font-size: 20px;
}

@media (max-width: 900px) {
    .app_about_footer {
        flex-direction: column;
        margin-top: unset;
        gap: 40px;
    }

    .app_about_partners {
        display: grid;
        grid-template-columns: auto auto;
    }

    .app_about_partners_item {
        width: 100px;
        height: 100px;
    }

    .app_about_partners_item>span {
        font-size: 12px;
        padding: 10px;
    }
}