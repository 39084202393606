.app_footer {
    background: var(--footer);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    position: relative;
    bottom: 0;
    width: 100%;
}

.app_footer span {
    color: var(--footer_text);
}