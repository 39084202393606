.app_header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 20vh;
    padding: 40px 0px 24px;
    margin: 40px 24px 0px;
}

.app_header img {
    height: 200px;
    width: 250px;
}

.app_header div {
    position: absolute;
    margin-left: 250px;
    font-size: 24px;
    font-family: 'Sacramento';
    color: var(--logo);
    letter-spacing: 0.4em;
}

@media(max-width: 900px) {
    .app_header{
        justify-content: flex-start;
        align-items: flex-end;
        padding: 40px 0px 24px;
        margin: 40px 24px 0px;
    }

    .app_header div {
        margin-left: 150px;
    }
}