.list_item_root {
    display: flex;
    flex-direction: column;
    margin: 0 10%;
    gap: 72px;
}

.list_item_root>div:first-of-type {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.list_item_overview {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: justify;
}

.list_item_overview :first-child {
    text-align: center;
}

.list_item_overview_public h5 {
    color: var(--text_dark);
    font-size: 14px;
    font-weight: bold;
    text-align: left !important;
    padding-bottom: 8px;
}

.list_item_overview_goals {
    display: flex;
    flex-direction: column;
    text-align: left !important;
    gap: 4px;
}

.list_item_overview_goals .body1 {
    text-align: left !important;
}

.list_item_overview_goals h5 {
    color: var(--text_dark);
    font-size: 14px;
    font-weight: bold;
    text-align: left !important;
    padding-bottom: 8px;
}

.list_item_overview_organization {
    display: flex;
    flex-direction: column;
}

.list_item_overview_organization h5 {
    color: var(--text_dark);
    font-size: 14px;
    font-weight: bold;
    text-align: left !important;
    padding-bottom: 8px;
}

.list_item_information {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-basis: 60%;
}

.list_item_information>:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list_item_information h2 {
    color: var(--titles);
    font-size: 24px;
}

.list_item_information h5 {
    color: var(--text_dark);
    font-size: 14px;
    font-weight: bold;
}

.list_item_structure {
    display: flex;
    flex-direction: column;
}

.list_item_structure :last-child {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.list_item_structure_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.list_item_languages {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}

.list_item_languages_item {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    align-items: center;
    font-family: "Avenir light";
    color: var(--text_base);
}

.list_item_languages_item_image {
    width: 20px;
    height: 20px;
    border-radius: 100px;
}

.list_item_price_contactus {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
}

.list_item_price_contactus_item {
    display: flex;
    flex-direction: row;
    gap: 14px;
    color: var(--text_base);
    font-family: "Avenir light";
}

.list_detail_price_value {
    font-family: "Avenir light";
    color: var(--text_base)
}

@media (max-width: 900px) {
    .list_item_root {
        margin: 0px 24px;
        gap: 32px;
    }

    .list_item_root>div:first-of-type {
        flex-direction: column;
    }

    .list_item_overview h1 {
        font-size: 32px;
    }

    .list_item_information>:last-child {
        flex-direction: column;
        gap: 24px;
    }

    .list_item_information>:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
    }
}